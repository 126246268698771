<template>
  <div>
    <header-slot :clients-search="true" :global-search="true">
      <template #actions>
        <b-row class="w-100">
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info">GLOBAL SEARCH</b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                @keyup.enter="search"
                placeholder="Search by Name, Account or the last 4 digits of Phone Number"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon"></feather-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'sp-update-request-process-origin' }"
        exact
        :exact-active-class="status === 0 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >IN PROCESS
        <span v-if="G_IN_PROCESS_UPDATE > 0" class="ml-1">
          <feather-icon
            icon
            :badge="G_IN_PROCESS_UPDATE > 99 ? '99+' : G_IN_PROCESS_UPDATE"
            badge-classes="badge-danger badge-glow"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'sp-update-request-to-revision-origin' }"
        exact
        :exact-active-class="status === 3 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >TO REVISION
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'sp-update-request-completed-origin' }"
        exact
        :exact-active-class="status === 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >COMPLETED
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'sp-update-request-deleted-origin' }"
        :exact-active-class="status === 2 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >DELETED
      </b-nav-item>
    </b-nav>

    <router-view></router-view>

    <modal-global-search
      v-if="showModalGlobalSearch"
      :program-id="programId"
      :search-text="searchText"
      :role-id="currentUser.role_id"
      @closeModal="showModalGlobalSearch = false"
    />
  </div>
</template>
<script>
//components
import { mapGetters, mapActions } from "vuex";

// MODALS
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalGlobalSearch,
  },
  data() {
    return {
      searchText: "",
      showModalGlobalSearch: false,
      programId: 7,
    };
  },
  async created() {
    await this.A_UPDATE_REQUEST_COUNTERS(this.moduleId);
  },
  computed: {
    status() {
      return this.$route.meta.status;
    },
    ...mapGetters({
      G_IN_PROCESS_UPDATE: "BoostCreditDisputeResult/G_IN_PROCESS_UPDATE",
      G_COMPLETED_UPDATE: "BoostCreditDisputeResult/G_COMPLETED_UPDATE",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      A_UPDATE_REQUEST_COUNTERS:
        "BoostCreditDisputeResult/A_UPDATE_REQUEST_COUNTERS",
      A_GLOBAL_SEARCH_CLIENTS: "clients-store/A_GLOBAL_SEARCH_CLIENTS",
    }),
    async search() {
      this.showModalGlobalSearch = true;
    },
  },
};
</script>